import React from 'react'

// import layout
import WorkLayout from '../../layout/work-layout'

// components
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../../utils/seo'

const Work = () => {
  // DEFINE THERE ALL THE WORK CONSTs
  const title = 'Non Solo Muse',
    desc =
      'Interviste alle poetesse del panorama contemporaneo italiano per Oxford University',
    roles = ['Videomaker'],
    videoSrc = [
      'https://www.youtube.com/watch?v=x-H-7GTLgAQ',
      'https://www.youtube.com/watch?v=o1-Ds-IttEg',
    ],
    // put this TRUE only if there are logos to show
    logo = true

  return (
    <WorkLayout
      title={title}
      roles={roles}
      videoSrc={videoSrc}
      logo={logo}
    >
      <Seo title={title} description={desc}/>
      {/* COVER IMG */}
      <StaticImage
        src='../../images/cover-oxford.jpg'
        alt='Cover per il lavoro Non solo Muse per Oxford University'
        className='work-cover shadow-black'
      />
      {/* DESC */}
      <p className='work-description desc-1 margin-bottom'>
        <span>
          <em>
            Non solo muse: panorama della poesia italiana{' '}
          </em>
          dal 1970 a oggi. Il progetto esamina e problematizza la nozione di
          scrittura femminile all’interno del contesto poetico italiano
          intervistando più di quindici poete.
        </span>
      </p>
      {/* DESC n.2 */}
      <p className='work-description desc-2'>
        <span>
          Un progetto a cura di <em>Adele Bardazzi</em> e{' '}
          <em>Roberto Binetti</em>.
        </span>
        <span>
          In collaborazione con{' '}
          <em>John Fell Fund of the University of Oxford</em>.
        </span>
        <span>Le interviste sono state registrate durante l’estate 2021.</span>
      </p>
      {/* ADD HERE ALL THE LOGOS */}
      <div className='logo-container'>
        <StaticImage
          src='../../images/logo/logo-oxford-2.png'
          alt='Logo Oxford University Jhon Fell Fund'
          className='work-logo-bigger logo-1'
        />
        <StaticImage
          src='../../images/logo/logo-oxford.png'
          alt='Logo Oxford University'
          className='work-logo-bigger logo-3'
        />
        <StaticImage
          src='../../images/logo/logo-opera.png'
          alt='Logo Opera Network'
          className='work-logo-mid logo-2'
        />
      </div>
    </WorkLayout>
  )
}

export default Work
